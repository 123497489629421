<template>
    <modal ref="modalRegistrarPago" titulo="Registrar Pago" no-aceptar icon="aprobar" adicional="Pagar" tamano="modal-lg">
        <div class="row mx-0 justify-center">
            <div class="col-5">
                <p class="pl-3 f-14 text-general">Seleccionar forma de pago</p>
                <el-select v-model="value" placeholder="Select" class="w-100">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col-6">
                <p class="pl-3 f-14 text-general">Caja / Cuenta bancaria</p>
                <el-select v-model="value" placeholder="Select" class="w-100">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 justify-center mt-3">
            <div class="col-5">
                <p class="text-general f-14 pl-3">Valor a pagar</p>
                <el-input v-model="valor_pagar" class="w-100" />
                <p class="text-general f-14 pl-3 mt-3">Comentario</p>
                <el-input v-model="comentario" class="w-100" type="textarea" :rows="5" placeholder="Deja un comentario" />
            </div>
            <div class="col-6">
                <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="height:217px;width:100%;background-color:#F5F5F5;border-radius:6px;" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            valor_pagar: '',
            comentario: '',
            slimOptions: {
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
        }
    },
    methods: {
        toggle(){
            this.$refs.modalRegistrarPago.toggle();
        }
    }
}
</script>